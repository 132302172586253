import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { Wrapper, P, H2 } from '../../../components'
import IconRound from '../../../assets/icons/tam-icon-check-20.svg'
import { useWindowDimensions } from '../../../helpers'
import { device } from '../../../theme/device'

const WrapperBoxInfo = styled((props) => <Wrapper {...props} />)`

  border-radius: 28px;
  break-inside: avoid;
  box-sizing: border-box;
  @media ${device.tablet} {
    border-radius: 32px;
  }
 
`

const BoxInfo = ({ title, items, id }) => {
  const size = useWindowDimensions()
  const width = _.get(size, 'width', 0)

  return (

    <WrapperBoxInfo
      backgroundColor="whiteSmoke"
      flexDirectionD="column"
      justifyContentD="flex-start"
      alignItemsD="flex-start"
      alignItemsM="flex-start"
      marginD="0 0 32px"
      marginM="0 0 24px"
      paddingM="32px 24px"
      paddingD="56px"
    >
      <Wrapper
        justifyContentD="flex-start"
        alignItemsD="flex-start"
        alignItemsM="flex-start"
        marginD="0 0 24px"
        marginM="0 0 24px">
        <H2
          font="manrope"
          fontWeightM="light"
          fontSizeD="4xl"
          lineHeightD="6xl">
          {title}
        </H2>
      </Wrapper>
      <Wrapper
        flexDirectionD="column">
        {
          _.map(items, (item, idx) => (
            <Wrapper
              flexDirectionM="row"
              justifyContentD="flex-start"
              alignItemsD="flex-start"
              justifyContentM="flex-start"
              alignItemsM="flex-start"
              key={item.name + idx}>
              <Wrapper
                style={{
                  flex: '1 1 24px',
                  margin: 0,
                }}
                widthM="24px">
                <IconRound
                  width={width > 768 ? '30px' : '26px'}/>
              </Wrapper>
              <P
                lineHeight="3xl"
                lineHeightD="3xl"
                marginD="0 0 16px 22px"
                margin="0 0 15px 16px">{item.name}</P>
            </Wrapper>
          ))
        }
      </Wrapper>
    </WrapperBoxInfo>

  )
}

export default BoxInfo
